import React, { useState } from 'react';
import axios from 'axios';

export const ServerDataContext = React.createContext(null);

const ServerDataProvider = props => {
  const [details, setDetails] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const getData = async cityId => {
    if (typeof window !== 'undefined') {
      try {
        const response = await axios.get(`https://us-central1-vr-crm.cloudfunctions.net/getWorkingHours?companyId=${cityId}`);
        setDetails(response.data);
        setDataLoaded(true);
      } catch {
        setDataLoaded(true);
      }
    }
  };

  return (
    <ServerDataContext.Provider
      value={{
        details,
        dataLoaded,
        getData,
      }}
    >
      {props.children}
    </ServerDataContext.Provider>
  )
}

export default ServerDataProvider;
