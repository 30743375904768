exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-birthday-index-js": () => import("./../../../src/pages/birthday/index.js" /* webpackChunkName: "component---src-pages-birthday-index-js" */),
  "component---src-pages-city-index-js": () => import("./../../../src/pages/city/index.js" /* webpackChunkName: "component---src-pages-city-index-js" */),
  "component---src-pages-games-index-js": () => import("./../../../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-gift-cards-index-js": () => import("./../../../src/pages/gift-cards/index.js" /* webpackChunkName: "component---src-pages-gift-cards-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

