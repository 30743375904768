import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import Provider from './locale'
import ServerDataProvider from './serverData';
import Theme from "./src/utils/theme"
import "./src/utils/fonts.css"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => (
  <Provider>
    <ServerDataProvider>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        {/* <GoogleReCaptchaProvider reCaptchaKey="6Lf31ZklAAAAAPpgpM9s16vvfzPatOBpp_Vv_KwN"> */}
          {element}
        {/* </ GoogleReCaptchaProvider> */}
      </ThemeProvider>
    </ServerDataProvider>
  </Provider>
)

const GlobalStyle = createGlobalStyle`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 112.5%;
    line-height: 1.45em;
    font-family: ${props => props.theme.fonts.futura}, georgia, serif;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    word-wrap: break-word;
    font-kerning: normal;
    font: inherit;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  a {
    text-decoration: none;
  }
 
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    cursor: pointer;
    font: inherit;
  }
  * {
    box-sizing: inherit;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p {
    margin: 0
  }
  b { 
    font-family: ${props => props.theme.fonts.futuraDemi};
  }
`
