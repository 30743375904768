const theme = {
  fonts: {
    futura: "Futura",
    futuraDemi: "FuturaDemi",
    futuraBold: "FuturaBold",
    courier: "Courier",
  },
  colors: {
    black: "#030303",
    green: "#2FA300",
    grey: "#6E6E6E",
    white: "#fff",
  },
  breakpoints: {
    xsmall: "only screen and (max-width: 375px)",
    mobile: "only screen and (max-width: 426px)",
    small: "only screen and (max-width: 600px)",
    tablet: "only screen and (max-width: 768px)",
    medium: "only screen and (max-width: 968px)",
    laptop: "only screen and (max-width: 1024px)",
    large: "only screen and (max-width: 1200px)",
    desktop: "only screen and (max-width: 1536px)",
  },
};
export default theme;
