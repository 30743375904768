import React, { useState, useContext, useEffect } from "react"
import { i18n } from './src/i18n.js'
import { I18nextContext, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

// add booking functionality using bookingServiceId

export const LocaleContext = React.createContext(null)

function fetchFromObject(obj, prop, options) {
  if(typeof obj === 'undefined') {
      return '';
  }

  var _index = prop.indexOf('.')
  if(_index > -1) {
      return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1), options);
  }

  return Object.keys(options).length ? obj[prop](options) : obj[prop];
}

export const useI18n = (path, options = {}) => {
  const { locale } = useContext(LocaleContext)
  const context = React.useContext(I18nextContext);
  return fetchFromObject(i18n[context.language], path, options)
}

export const useCustomI18n = (obj) => {
  const { locale } = useContext(LocaleContext)
  const context = React.useContext(I18nextContext);
  return obj[context.language]
}

const Provider = props => {
  const [locale, setlocale] = useState(() => {
    let initialValue = "ua"

    if (typeof window !== "undefined") {
      if (window.localStorage) {
        const localeFromStorage = window.localStorage.getItem("gatsby-i18next-language")

        if (localeFromStorage && ["ua", "ru", "en"].includes(localeFromStorage)) {
          initialValue = localeFromStorage
        }
      }
    }

    return initialValue
  })
  const changeLocale = value => {
    setlocale(value)

    if (typeof window !== "undefined" && window.localStorage) {
      window.localStorage.setItem("gatsby-i18next-language", value)
    }
  }

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale,
      }}
    >
      {props.children}
    </LocaleContext.Provider>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Provider
