export const i18n = {
  ua: {
    chooseCity: "Виберіть своє місто",
    navbar: {
      prices: 'Ціни',
      contacts: 'Контакти',
      birthdays: 'Дні народження',
      certificates: 'Сертифікати',
      book: 'Забронювати',
      games: 'Ігри',
      reviews: 'Відгуки',
      faq: 'FAQ',
      country: 'Україна'
    },
    footer: {
      country: 'Україна'
    },
    main: {
      aboutUs: {
        whyYou: 'Чому ви',
        comeAgain: 'прийдете грати до нас знову?',
        fullFreedom: 'Повна свобода',
        freedomDetails:
          'Гравець може легко переміщатися на майданчику 10 м2. Всі рухи гравця відстежуються та переносяться у віртуальний світ',
        closedClub: 'Закритий клуб',
        closedDetails:
          'У клуб можна потрапити лише за фразою-паролем (дізнатися її можна за допомогою нашого чат-бота для Viber та Telegram)',
        atmosphere: 'Особлива атмосфера',
        atmosphereDetails:
          'На вході в клуб вас зустріне адміністратор – ваш провідник у світ віртуальної реальності. Зі своєю помічницею вони познайомлять вас з тим, про що ви навіть не підозрювали',
        multiplayerGame: 'Гра у мережі',
        multiplayerDetails:
          'Грайте один проти одного чи разом проти всіх! (від 1 до 4 осіб одночасно)'
      },
      booking: {
        title: 'Забронюйте',
        time: 'час',
        gameIn: 'гри в',
        rightNow: 'прямо зараз!'
      },
      description: {
        welcome: 'Ласкаво просимо',
        future: 'в майбутнє',
        vrIn: 'Віртуальна реальність у ',
        vrInDetails: 'Віртуальна реальність у ',
        details:
          'сьогодні є ще новинкою, але вже набула великої популярності. Клуб MATRIX дозволяє відвідувачам перенестися з реального світу до віртуального, де на них чекають неймовірні пригоди. А вони безпосередньо залежать від вибору ігор. Учасники можуть перенестися на підводний корабель або вивчити хімічні сполуки, боротися з інопланетянами, монстрами, які хочуть захопити Землю.',
        showAll: 'Показати все',
        moreDetails:
          'Клуб MATRIX пропонує своїм клієнтам тільки ті ігри, що відрізняються чудовою графікою та чітким зображенням. Це є запорукою максимального занурення та ваших позитивних емоцій. Також у нас ви не зустрінете примітивні ігри, які полягають у перенесенні будь-яких предметів. Кожна гра має свій певний сюжет, що набагато цікавіше.',
        wantToPlay: 'Забронювати гру'
      },
      firstScreen: {
        title: 'Перевір на собі нові технології',
        vnIn: 'віртуальної реальності в',
        offer1: 'Якщо вам не сподобається —',
        offer2: 'ми повернемо вам гроші!',
        wantToPlay: 'Забронювати гру'
      },
      price: {
        offer1: 'Якщо вам не сподобається -',
        offer2: 'ми повернемо вам гроші!',
        weekdays: ({time}) => `БУДНІ ДО ${time}:00`,
        weekdaysDetails:
          'Час здивування, нерозуміння де ти перебуваєш і бажання повторити',
        currency: 'грн',
        duration: '60 ХВИЛИН*',
        book: 'Забронювати',
        weekends: ({time}) => `БУДНІ ПІСЛЯ ${time}:00 І ВИХІДНІ`,
        weekendsDetails:
          'Година захоплення, запаморочливих погонь та натовпу вбитих монстрів',
        giftCards: 'ПОДАРУНКОВІ КАРТИ',
        moreDetails: 'Детальніше',
        birthdays: 'ДНІ НАРОДЖЕННЯ',
        hint1: '* Вартість вказана за 1 особу.',
        hint2:
          'Тривалість сеансу 60 хв. Він включає інструктаж і 50 хв ігрового часу. При повторному відвідуванні MATRIX VR інструктаж проводити не потрібно, тому ігровий час збільшується.',
        allWeek: 'БУДНІ І ВИХІДНІ'
      },
      reviews: {
        title: 'Відгуки',
        real: 'реальних',
        people: 'людей',
        rating: 'Рейтинг',
        outOf: 'з',
        goToReview: 'Перейти к отзыву'
      },
      rules: {
        title: 'Правила',
        our: 'нашого',
        club: 'клубу',
        password: 'Пароль',
        passwordDetails:
          "Для входу до клубу вам обов'язково потрібно знати фразу-пароль. Її можна дізнатися за допомогою нашого чат-бота для Viber чи Telegram",
        alcohol: 'Алкоголь',
        warning:
          "Просимо утриматися від відвідування клубу у стані алкогольного чи наркотичного сп'яніння",
        age: 'Вікове',
        restriction: 'обмеження',
        restrictionDetails:
          "Ми не рекомендуємо дітям віком до 10 років відвідувати клуб. Це пов'язано із технічними особливостями устаткування, і навіть з індивідуальним сприйняттям дитини",
        pregnant: 'Вагітні',
        pregnantDetails:
          'Наполегливо не рекомендуємо грати вагітним та емоційно вразливим людям',
        visit: 'Час відвідування',
        visitDetails:
          'Прохання приходити не раніше ніж за 5 хвилин до сеансу, а у разі запізнення попередити про це провідника',
        required: "Обов'язковий",
        childAccompany: 'супровід дитини',
        accompanyDetails:
          'Діти віком до 12 років можуть перебувати в клубі лише у супроводі хоча б одного з дорослих довірених осіб'
      },
      games: {
        title: 'У що можна',
        play: 'пограти?',
        playersShort: 'К-ть игроков:',
        playersLong: 'Кількість гравців:',
        genre: 'Жанр:',
        description: 'Опис:',
        book: 'Забронювати',
        fullGameList: 'Повний перелік ігор',
        shooter: 'Шутер',
        children: 'Для дітей',
        sport: 'Спорт',
        simulator: 'Симулятор',
        quest: 'Квест',
        horror: 'Хорор',
        magic: 'Магія',
        stategy: 'Стратегія',
        'social-network': 'Соціальна мережа'
      },
      contacts: {
        cta: 'Приходьте, дзвоніть!',
        partnership: 'Для співпраці',
        proposals: 'і пропозицій:',
        wantToPlay: 'Забронювати гру',
        city: 'м.'
      }
    },
    birthday: {
      firstScreen: {
        modern: 'Сучасний',
        birthday: 'День народження',
        birthdayFor: 'для дорослих і дітей в MATRIX',
        wantToPlay: 'Замовити свято'
      },
      about: {
        whyUs: 'У нас ти отримаєш',
        fullFreedom: 'Повна свобода і командна гра',
        freedomDetails:
          'Під час гри ви рухаєтеся у спеціально обладнаному просторі 10м2, де рухи учасників відстежуються. Ви з друзями повністю взаємодієте у грі та не можете розрізнити, які компоненти віртуальних подій є нереальними, оскільки занурення у цифровий світ вражає.',
        atmosphere: 'Особлива атмосфера',
        atmosphereDetails:
          'Наш провідник у світ віртуальної реальності чудово обізнаний у сфері розваг, тому підбере ігрову програму відповідно до ваших побажань. Зважайте на те, що час у віртуальному світі летить надто швидко.',
        emotions: 'Незабутні емоції',
        emotionsDetails:
          'Перше занурення у віртуальний простір неможливо з чимось порівняти. Мозок швидко пристосовується до нової ролі, тілом керують маніпулятори та контролери, тому складається враження, що лук та стріли – звичні атрибути. Адміністратор розкриє кілька секретів сюжетних ліній, і ваша команда переможе орків. Як щодо такого дня народження?',
        alternative: 'Альтернатива для дітей',
        alternativeDetails:
          "Віртуальна реальність нараховує безліч ігор, які не мають згубного впливу на дитячу психіку, проте розвивають корисні навички, вчать командній роботі та виховують лідерські якості. Невже пасивний відпочинок за комп'ютером може порівнятися з віртуальними вихідними в парку, крутішим за Діснейленд?",
        wantToBook: 'Хочу відсвяткувати тут',
        whatYouGet: 'У нас ти отримаєш',
        whatYouGetDetails:
          'Незабутньо! Ми дійсно впевнені, що VR зможе відвернути тебе від буденності. Рівень занурення, який отримуєш від гри, є дуже реалістичним. Розкриємо кілька секретів і для більш детального ознайомлення наводимо приклад сценарію для оптимального часу в MATRIX - 3 години.',
        preparation: 'Зустріч гостей та підготовка до святкування',
        preparationDetails:
          'Ви можете привезти елементи декору або святкового оформлення, а також заздалегідь обговорити перелік ігор.',
        minutes: 'хв',
        instructions: 'Інструктаж та перший ігровий час',
        instructionsDetails:
          'Адміністратор інформує гостей про правила перебування в ігровому просторі та дає короткі інструкції. Тепер можна досліджувати дно океану та стріляти з лука, адже зараз ваша команда влаштує битву з орками. Саме ця гра не вимагає від гостя особливих навичок та є оптимальною для новачка у світі віртуальної реальності.',
        breakTime: 'Перерва для відпочинку',
        breakTimeDetails:
          'Ставимо на паузу запеклі баталії, адже попереду чекає пограбування банку та крадіжка секретної інформації.',
        secondPlay: 'Другий ігровий час',
        secondPlayDetails:
          'Ще вчора ти дивився відео підводного світу, а вже сьогодні тобі показують нову реальність – VR, яка створює ілюзію подорожі до Атлантиди. Занурившись одного разу в цю фантастичну атмосферу, ти неодмінно захочеш повторити досвід і ще раз відвідати печеру Бетмена або зіграти у гру на виживання',
        photoTime: 'Час для фотографій та обговорення VR-досвіду',
        photoTimeDetails:
          'Настав час повертатися в реальне життя і збирати речі, публікувати фотографії, обговорювати програму лояльності MATRIX та новинки VR.'
      },
      price: {
        title: 'Вартість',
        weekdays19: 'БУДНІ ДО 19:00',
        weekdaysDetails:
          'Час здивування, нерозуміння де ти перебуваєш і бажання повторити',
        currency: 'грн',
        duration: '60 ХВИЛИН*',
        book: 'Забронювати',
        weekends: 'БУДНІ ПІСЛЯ 19:00 І ВИХІДНІ',
        weekendsDetails:
          'Час захоплення, запаморочливих погонь та натовпу вбитих монстрів',
        upfront1: 'Для підтвердження бронювання необхідно',
        upfront2: 'внести передоплату у розмірі 50%',
        allWeek: 'БУДНІ І ВИХІДНІ'
      },
      faq: {
        question1: 'З якого віку моя дитина може грати?',
        answer1:
          "Ми рекомендуємо відвідування клубу дітям віком від 10 років. Це пов'язано з технічними особливостями обладнання та індивідуальним сприйняттям дитини.",
        question2: 'Моїй дитині ще немає 10 років. Чому йому не можна грати?',
        answer2:
          "В першу чергу, обладнання розраховане на дітей старшого віку. Адміністратор, ймовірно, не зможе надійно закріпити шолом на голові вашої дитини. Тому є ризик, що під час гри шолом спаде та розіб'ється. Оскільки гості несуть матеріальну відповідальність за обладнання, ми щосили намагаємося не допустити таких ситуацій.",
        question3: 'Чи потрібно залишати передоплату?',
        answer3:
          "Так, після відправки заявки, з вами зв'яжеться з вами оператор кол-центру, щоб обговорити всі деталі вашого свята. Для підтвердження бронювання вам необхідно буде зробити передплату в розмірі 50%. Передплату можна буде зробити у вигляді безготівкового платежу або оплатити готівкою безпосередньо на клубі.",
        question4: 'Чи є знижки  подарунки для іменинника?',
        answer4:
          'У нас не передбачено додаткових знижок для іменинників. Але кожен гість MATRIX після проведення свята матиме можливість придбати подарункову картку MATRIX за спеціальною пропозицією 2 за ціною 1. А також, кожну 5 гру гості отримають у подарунок.',
        question5: "Чи обов'язково батькам перебувати в клубі під час гри?",
        answer5:
          'Так, ми рекомендуємо одному-двом батькам залишитися з дітьми на весь ігровий час у клубі. Ігри у віртуальній реальності є досить активними, тому додаткове спостереження за дітьми з метою безпеки не буде зайвим.',
        question6:
          'Моя дитина виглядає старшою за своїх однолітків. Він вміє дотримуватись правил, і взагалі я впевнений, що з ним не виникне проблем. Йому можна прийти до MATRIX?',
        answer6:
          'Так, ми можемо забронювати для вас гру. Але попереджаємо, що у двох випадках адміністратор все ж таки може її скасувати. Перший – якщо шолом не вдасться надійно закріпити на голові дитини. Другий - якщо поведінка дитини під час гри у віртуальну реальність провокуватиме ризик заподіяння шкоди собі, оточуючим, або обладнанню. У цьому випадку гроші за візит ми не візьмемо, тому що велику цінність для нас представляють ваші позитивні емоції. Можливо, варто трохи почекати, поки дитина підросте, щоб вона могла сповна насолодитися процесом гри.',
        question7:
          'Дитина хоче пограти, але я не можу (або не хочу) бути з нею у клубі. Я можу залишити його одного під час гри?',
        answer7:
          'Діти можуть сприймати те, що відбувається у віртуальній реальності спотворено, а в ході гри завдати шкоди собі, оточуючим або вивести з ладу обладнання. Якщо вашій дитині менше 15 років, ви не можете залишити її в клубі без додаткового контролю хоча б одного з батьків або іншої дорослої довіреної особи.',
        question8: 'Чи підходить ця послуга для дітей?',
        answer8:
          'Звичайно, ми будемо раді провести дитячий день народження для гостей віком від 10 років. Для дітей молодше суворо радимо продумати якісь інші розваги, тому що VR не рекомендується для дітей такого віку.',
        question9:
          "З чим пов'язана кількість часу, що рекомендується, для дня народження 2-3 години? Ми можемо забронювати 1:00?",
        answer9:
          'На наш досвід, дві години гри – це золотий стандарт для компаній із чотирьох осіб. За першу годину всі увійдуть у смак і добре освоїться у віртуальній реальності. О другій годині будуть введені складніші та цікавіші ігри та місії. Три години ми рекомендуємо для великих компаній більше 4-х осіб, а також для гіперактивних дітей. В інших випадках краще обмежитися двома годинами. Куди краще, коли залишається легке почуття недомовленості та бажання якомога раніше повернутися у віртуальну реальність, ніж втома від гри. Тим не менш, ви можете забронювати 1 годину гри для компанії до 6 осіб, якщо людей у ​​компанії більше, то мінімальна кількість ігрового часу для броні – від 2 годин',
        question10:
          'Яким чином буде організовано гру, якщо місць для гри 4, а гравців буде більше?',
        answer10:
          'Не хвилюйтесь! Адміністратор організує ігровий час так, щоб усі гості змогли грати по черзі. Атмосфера в клубі така, що весело не лише гратиме, а й спостерігатиме за тим, що відбувається, тому після закінчення свята всі залишаться задоволені.',
        question11: 'Чи можемо ми взяти свій алкоголь, їжу та соки/газування?',
        answer11:
          "Через особливості ігрового процесу грати у стані алкогольного сп'яніння заборонено. Але ви можете взяти з собою їжу та безалкогольні напої, щоб зробити паузу і набратися сил для ваших віртуальних пригод.",
        question12:
          'Чи будуть сторонні люди у клубі під час проведення мого свята?',
        answer12:
          'Ні. Клуб орендується виключно для проведення Вашого свята та крім вас та ваших друзів у клубі не буде жодних сторонніх людей.'
      },
      description: {
        title: 'Чому так круто святкувати',
        title2: 'День народження у MATRIX?',
        details:
          'Тобі знайомі надто реалістичні сни, в яких літаєш над прірвою, вбиваєш зомбі і перетворюєшся на героїв улюблених коміксів? А тепер уяви, що це можна зробити в межах однієї кімнати. Звичайні дні народження в ресторані, пабі або дитячому розважальному просторі скоро стануть пережитками минулого, зате симулятори віртуальної реальності набувають все більшої популярності. Уяви, що ти досліджуєш дно океану, стріляєш із лука і керуєш піратським кораблем.',
        detail2:
          'У твоєму розпорядженні буде весь ігровий простір клубу, де ти зможеш провести час великою компанією, і всі ви зможете насолодитися ігровим процесом. Ви зможете спробувати велику кількість ігор, граючи в одній команді, один проти одного або кожен індивідуально.',
        detail3:
          'Вас обмежуватимуть лише ваші бажання. Якщо ви відчули що зголодніли і захочете перекусити і відпочити від гри, ви можете зробити це у нас в клубі, попередньо замовивши їжу, або ж узявши її з собою. Також, ви зможете принести торт із солодощами та урочисто задути свічки, загадавши при цьому бажання. Після перекушування, набравшись сил, ти знову зможеш поринути у віртуальний світ. Таким чином, ваша компанія проведе свято, яке принесе вам багато позитивних емоцій!'
      },
      modal: {
        date: 'Дата',
        request: 'Оформити заявку',
        name: "Ваше им'я",
        phone: 'Номер телефону*',
        choose_club: 'Виберіть клуб',
        note: 'Коментар',
        book: 'Забронювати'
      }
    },
    gift: {
      firstScreen: {
        title1: 'Потрібен',
        title2: 'крутий',
        title3: 'подарунок?',
        details1: 'Даруй емоції своїм близьким',
        details2: 'разом з клубом віртуальної',
        details3: 'реальності MATRIX',
        cost: 'Вартість в',
        currency: 'грн',
        hint: '*ціна може відрізнятися в інших містах'
      },
      card: {
        title1: 'ПОДАРУНКОВА',
        title2: 'КАРТКА НА 1 ГОДИНУ',
        description1: 'здивування, нерозуміння де ти',
        description2: 'знаходишся і бажання повторити',
        orderCard: 'Замовити картку'
      },
      receive: {
        caption1: 'Подарункова карта дає право на 1 годину гри одній людині в тому клубі, де ця карта була придбана.',
        caption2: 'Придбати можна необмежену кількість карт.',
        caption3: 'Пропонуємо 2 способи отримати карту:',
        title: 'Як отримати картку?',
        first: 'Перший спосіб',
        details0: 'Придбати подарункові сертифікати безпосередньо у клубі попередньо домовившись про день та час вашого візиту.',
        details2_0: 'Ми відправимо їх вам Новою поштою за передплатою на карту.',
        details1: 'Можете забрати карти',
        details2: 'безпосередньо у нас у клубі,',
        details3: 'попередньо домовившись',
        details4: 'із адміністратором',
        second: 'Другий спосіб',
        details5: 'Ми можемо відправити їх',
        details6: 'вам Новою поштою по',
        details7: 'передоплаті на карту'
      },
      description: {
        title: 'Чому це крутий подарунок?',
        details1:
          'Шукаєте крутий подарунок своїм близьким? Ми допоможемо вам та пропонуємо',
        details2: 'купити сертифікат у клуб віртуальної реальності!',
        unlimited:
          'Термін дії сертифіката 1 рік, одержувач сам обирає час його використання',
        family:
          'Можливість занурення від 1 до 4 осіб одночасно, тому це може бути крутим подарунком для всієї родини',
        games: 'Велика кількість класних ігор на будь-який смак і вік',
        thanks:
          'Після візиту в MATRIX, вам ще довго будуть дякувати за такий подарунок, а не просто покладуть на полицю з непотрібними речами'
      },
      about: {
        what: 'Що таке',
        matrix: 'MATRIX?',
        watch: 'Подивися це відео і ти зрозумієш, чому понад 100 000',
        tried: 'людей вже випробували на собі що таке'
      },
      contact: {
        us: "Зв'яжіться з нами для",
        purchase: 'придбання сертифіката:'
      },
      modal: {
        order: 'Замовити картку',
        name: "Ваше ім'я",
        phone: 'Номер телефону*',
        quantity: 'К-ть карт',
        uah: 'грн',
        choose_club: 'Виберіть клуб',
        note: 'Коментар',
        send: 'Відправити заявку'
      }
    },
    thankYou: {
      title: 'Дякую!',
      order1: 'Вашу заявку прийнято, ми незабаром вам передзвонимо.',
      order2: 'Бронь ігрового сеансу вважається дійсною',
      order3: 'тільки після підтвердження оператором кол-центру.',
      hint1: 'Відповідно до Закону України “Про запобігання',
      hint2: 'поширення коронавірусу” для входу в клуб потрібна',
      hint3: 'захисна маска',
      rules: 'Не забуваємо про правила',
      backToMain: 'На головну'
    },
    404: {
      oops: 'Упс...',
      notFound: 'Такої сторінки не існує',
      backToMain: 'Повернутися на головну'
    },
    gamelist: {
      whatToPlay: 'У що можна погратись?',
      gameGenres: 'Ігри з жанрів:',
      allGames: 'Усі ігри',
      reset: 'Скинути',
      moreDetails: 'Докладніше'
    }
  },
  ru: {
    chooseCity: "Выберите свой город",
    navbar: {
      prices: 'Цены',
      contacts: 'Контакты',
      birthdays: 'Дни рождения',
      certificates: 'Сертификаты',
      book: 'Забронировать',
      games: 'Игры',
      reviews: 'Отзывы',
      faq: 'FAQ',
      country: 'Украина'
    },
    footer: {
      country: 'Украина'
    },
    main: {
      aboutUs: {
        whyYou: 'Почему вы',
        comeAgain: 'придете играть к нам снова?',
        fullFreedom: 'Полная свобода',
        freedomDetails:
          'Игрок с легкостью может перемещаться на площадке 10 м2. Все движения игрока отслеживаются и переносятся в виртуальный мир',
        closedClub: 'Закрытый клуб',
        closedDetails:
          'В клуб можно попасть только по паролю (узнать его можно с помощью нашего чат-бота для Viber и Telegram)',
        atmosphere: 'Особая атмосфера',
        atmosphereDetails:
          'На входе в клуб вас встретит администратор - ваш проводник в мир виртуальной реальности. Со своей помощницей они познакомят вас с тем, о чем вы даже не подозревали',
        multiplayerGame: 'Сетевая игра',
        multiplayerDetails:
          'Играйте друг против друга или вместе против всех! (от 1 до 4 человек одновременно)'
      },
      booking: {
        title: 'Забронируйте',
        time: 'время',
        gameIn: 'игры в',
        rightNow: 'прямо сейчас!'
      },
      description: {
        welcome: 'Добро пожаловать',
        future: 'в будущее',
        vrIn: 'Виртуальная реальность в ',
        vrInDetails: 'Виртуальная реальность в ',
        details:
          'сегодня является еще новинкой, но уже приобрела большую популярность. Клуб MATRIX позволяет посетителям перенестись из реального мира в виртуальный, где их ожидают невероятные приключения. А они напрямую зависят от выбора игр. Участники могут перенестись на подводный корабль или изучить химические соединения, могут бороться с инопланетянами, монстрами, которые хотят захватить Землю.',
        showAll: 'Показать всё',
        moreDetails:
          'Клуб MATRIX предлагает своим клиентам только те игры, что отличаются прекрасной графикой и четким изображением. Это является залогом максимального погружения и ваших положительных эмоций. Также у нас вы не встретите примитивные игры, которые заключаются в переносе каких-либо предметов. Каждая игра имеет свой определенный сюжет, что гораздо интереснее.',
        wantToPlay: 'Забронировать игру'
      },
      firstScreen: {
        title: 'Проверь на себе новые технологии',
        vnIn: 'виртуальной реальности в',
        offer1: 'Если вам не понравится —',
        offer2: 'мы вернем вам деньги!',
        wantToPlay: 'Забронировать игру'
      },
      price: {
        offer1: 'Если вам не понравится -',
        offer2: 'мы вернем вам деньги!',
        weekdays: ({time}) => `БУДНИЕ ДО ${time}:00`,
        weekdaysDetails:
          'Час удивления, непонимания где ты находишься и желания повторить',
        currency: 'грн',
        duration: '60 МИНУТ*',
        book: 'Забронировать',
        weekends: ({time}) => `БУДНИЕ ПОСЛЕ ${time}:00 И ВЫХОДНЫЕ`,
        weekendsDetails:
          'Час восторга, головокружительных погонь и толпы убитых монстров',
        giftCards: 'ПОДАРОЧНЫЕ КАРТЫ',
        moreDetails: 'Подробнее',
        birthdays: 'ДНИ РОЖДЕНИЯ',
        hint1: '* Стоимость указана за 1 человека.',
        hint2:
          'Продолжительность сеанса 60 мин. Он включает в себя инструктаж и 50 мин игрового времени. При повторном посещении MATRIX VR инструктаж проводить не нужно, поэтому игровое время увеличивается.',
        allWeek: 'БУДНИЕ И ВЫХОДНЫЕ'
      },
      reviews: {
        title: 'Отзывы',
        real: 'реальных',
        people: 'людей',
        rating: 'Рейтинг',
        outOf: 'из',
        goToReview: 'Перейти к отзыву'
      },
      rules: {
        title: 'Правила',
        our: 'нашего',
        club: 'клуба',
        password: 'Пароль',
        passwordDetails:
          'Для входа в клуб вам обязательно нужно знать фразу-пароль. Ее можно узнать с помощью нашего чат-бота для Viber или Telegram',
        alcohol: 'Алкоголь',
        warning:
          'Просим воздержаться от посещения клуба в состоянии алкогольного или наркотического опьянения',
        age: 'Возрастное',
        restriction: 'ограничение',
        restrictionDetails:
          'Мы не рекомендуем детям до 10 лет посещать клуб. Это связано с техническими особенностями оборудования, а также с индивидуальным восприятием ребёнка',
        pregnant: 'Беременные',
        pregnantDetails:
          'Настоятельно не советуем играть беременным и эмоционально впечатлительным людям',
        visit: 'Время посещения',
        visitDetails:
          'Просьба приходить не ранее чем за 5 минут до сеанса, а в случае опоздания предупредить об этом проводника',
        required: 'Обязательное',
        childAccompany: 'сопровождение ребёнка',
        accompanyDetails:
          'Дети в возрасте до 12 лет могут находиться в клубе только в сопровождении хотя бы одного из взрослых доверенных лиц'
      },
      games: {
        title: 'Во что можно',
        play: 'поиграть?',
        playersShort: 'Кол-во игроков:',
        playersLong: 'Количество игроков:',
        genre: 'Жанр:',
        description: 'Описание:',
        book: 'Забронировать',
        fullGameList: 'Полный список игр',
        shooter: 'Шутер',
        children: 'Для детей',
        sport: 'Спорт',
        simulator: 'Симулятор',
        quest: 'Квест',
        horror: 'Хоррор',
        magic: 'Магия',
        stategy: 'Стратегия',
        'social-network': 'Социальная сеть'
      },
      contacts: {
        cta: 'Приходите, звоните!',
        partnership: 'Для сотрудничества',
        proposals: 'и предложений:',
        wantToPlay: 'Забронировать игру',
        city: 'г.'
      }
    },
    birthday: {
      firstScreen: {
        modern: 'Современный',
        birthday: 'День рождения',
        birthdayFor: 'для взрослых и детей в MATRIX',
        wantToPlay: 'Заказать праздник'
      },
      about: {
        whyUs: 'У нас ты получишь',
        fullFreedom: 'Полная свобода и командная игра',
        freedomDetails:
          'Во время игры вы двигаетесь в специально оборудованном пространстве 10м2, где движения участников отслеживаются. Вы с друзьями полностью взаимодействуете в игре и не можете различить, какие компоненты виртуальных событий являются нереальными, поскольку погружение в цифровой мир поражает.',
        atmosphere: 'Особая атмосфера',
        atmosphereDetails:
          'Наш проводник в мир виртуальной реальности прекрасно осведомлен в сфере развлечений, поэтому подберет игровую программу в соответствии с вашими пожеланиями. Учитывайте то, что время в виртуальном мире летит слишком быстро.',
        emotions: 'Незабываемые эмоции',
        emotionsDetails:
          'Первое погружение в виртуальное пространство невозможно с чем-то сравнить. Мозг быстро приспосабливается к новой роли, телом управляют манипуляторы и контроллеры, поэтому создается впечатление, что лук и стрелы - привычные атрибуты. Администратор раскроет несколько секретов сюжетных линий и ваша команда победит орков. Как насчет такого дня рождения?',
        alternative: 'Альтернатива для детей',
        alternativeDetails:
          'Виртуальная реальность насчитывает множество игр, которые не имеют пагубного влияния на детскую психику, зато развивают полезные навыки, учат командной работе и воспитывают лидерские качества. Неужели пассивный отдых за компьютером может сравниться с виртуальными выходными в парке, покруче Диснейленда?',
        wantToBook: 'Хочу отпраздновать тут',
        whatYouGet: 'У нас ты получишь',
        whatYouGetDetails:
          'Незабываемо! Мы действительно уверены в том, что VR сможет отвлечь тебя от обыденности. Уровень погружения, который получаешь от игры, очень реалистичный. Раскроем несколько секретов и для более детального ознакомления приводим в пример сценарий оптимального времени в MATRIX– 3 часа.',
        preparation: 'Встреча гостей и подготовка к празднованию',
        preparationDetails:
          'Вы можете привезти элементы декора или праздничного оформления, а также заранее обсудить с администратором перечень игр.',
        minutes: 'мин',
        instructions: 'Инструктаж и первое игровое время',
        instructionsDetails:
          'Администратор информирует гостей о правилах пребывания в игровом пространстве и даёт краткие инструкции. Теперь можно исследовать дно океана и стрелять из лука, ведь сейчас ваша команда устроит побоище с орками. Именно эта игра не требует от гостя особых навыков и является оптимальной для новичка в мире виртуальной реальности.',
        breakTime: 'Перерыв для отдыха',
        breakTimeDetails:
          'Ставим на паузу ожесточенные баталии, ведь впереди ждёт ограбление банка и кража секретной информации.',
        secondPlay: 'Второе игровое время',
        secondPlayDetails:
          'Еще вчера ты смотрел видео подводный мир, а уже сегодня тебе показывают новую реальность - VR, которая создает иллюзию путешествия в Атлантиду. Погрузившись однажды в эту фантастическую атмосферу, ты непременно захочешь повторить опыт и еще раз посетить пещеру Бэтмена или сыграть в игру на выживание',
        photoTime: 'Время для фотографий и обсуждения VR-опыта',
        photoTimeDetails:
          'Пора возвращаться в реальную жизнь и собирать вещи, публиковать фотографии, обсуждать программу лояльности MATRIX и новинки VR.'
      },
      price: {
        title: 'Стоимость',
        weekdays19: 'БУДНИЕ ДО 19:00',
        weekdaysDetails:
          'Час удивления, непонимания где ты находишься и желания повторить',
        currency: 'грн',
        duration: '60 МИНУТ*',
        book: 'Забронировать',
        weekends: 'БУДНИЕ ПОСЛЕ 19:00 И ВЫХОДНЫЕ',
        weekendsDetails:
          'Час восторга, головокружительных погонь и толпы убитых монстров',
        upfront1: 'Для подтверждения бронирования необходимо',
        upfront2: 'внести предоплату в размере 50%',
        allWeek: 'БУДНИЕ И ВЫХОДНЫЕ'
      },
      faq: {
        question1: 'С какого возраста мой ребёнок может играть?',
        answer1:
          'Мы рекомендуем посещение клуба детям в возрасте от 10 лет. Это связано с техническими особенностями оборудования, а также с индивидуальным восприятием ребёнка.',
        question2: 'Моему ребёнку ещё нет 10 лет. Почему ему нельзя играть?',
        answer2:
          'Изначально оборудование рассчитано на детей более старшего возраста, и администратор, вероятно, не сможет надёжно закрепить шлем на голове вашего ребенка. Поэтому есть риск, что во время игры шлем спадёт и разобьётся. Так как гости несут материальную ответственность за оборудование, мы изо всех сил стараемся не допустить таких ситуаций.',
        question3: 'Нужно ли оставлять предоплату?',
        answer3:
          'Да, после отправки заявки с вами свяжется оператор колл-центра, чтобы обсудить все детали вашего праздника. Для подтверждения бронирования вам необходимо будет внести предоплату в размере 50%. Предоплату можно будет сделать в форме безналичного платежа или оплатить наличными непосредственно на клубе.',
        question4: 'Есть ли скидки  подарки для именинника?',
        answer4:
          'У нас не предусмотрены дополнительные скидки для именинников. Но каждый гость MATRIX после проведения праздника будет иметь возможность приобрести подарочную карту MATRIX по специальному предложению 2 по цене 1. А также, каждую 5-ю игру гости получат в подарок.',
        question5: 'Обязательно родителям находиться в клубе во время игры?',
        answer5:
          'Да, мы рекомендуем одному-двум родителям остаться с детьми на все игровое время в клубе. Игры в виртуальной реальности достаточно активны, поэтому дополнительное наблюдение за детьми в целях безопасности не будет лишним.',
        question6:
          'Мой ребенок выглядит старше своих сверстников. Он умеет соблюдать правила, и вообще я уверен, что с ним не возникнет проблем. Ему можно прийти в MATRIX?',
        answer6:
          'Да, мы можем забронировать для вас игру. Но предупреждаем, что в двух случаях администратор всё же может ее отменить. Первый - если шлем не удастся надежно закрепить на голове ребенка. Второй - если поведение ребёнка во время игры в виртуальную реальность будет провоцировать риск нанесения вреда себе, окружающим, или оборудованию. В этом случае, деньги за визит мы не возьмём, потому что бОльшую ценность для нас представляют ваши положительные эмоции. Возможно, стоит немного подождать, пока ребёнок подрастёт, чтобы он мог сполна насладиться процессом игры.',
        question7:
          'Ребенок хочет поиграть, но я не могу (или не хочу) быть с ним в клубе. Я могу оставить его одного на время игры?',
        answer7:
          'Дети могут воспринимать происходящее в виртуальной реальности искажено, а в ходе игры нанести вред себе, окружающим или вывести из строя оборудование. Если вашему ребёнку меньше 15 лет,  вы не можете оставить его в клубе без дополнительного контроля хотя бы одного из родителей или другого взрослого доверенного лица.',
        question8: 'Подходит ли данная услуга для детей?',
        answer8:
          'Конечно, мы будем рады провести детский день рождения для гостей в возрасте от 10 лет. Для детей младше строго рекомендуем продумать какие-то другие развлечения, потому что VR не рекомендуется для детей такого возраста.',
        question9:
          'С чем связана рекомендуемое количество времени для дня рождения 2-3 часа? Мы можем забронировать 1 час?',
        answer9:
          'По нашему опыту, два часа игры – это золотой стандарт для компаний из 4 человек. За первый час все войдут во вкус и хорошо освоятся в виртуальной реальности. Во второй час будут введены более сложные и интересные игры и миссии. Три часа мы рекомендуем для больших компаний больше 4 человек, а также для гиперактивных детей. В остальных случаях лучше ограничиться двумя часами. Куда лучше, когда остается легкое чувство недосказанности и желание как можно раньше вернуться в виртуальную реальность, чем усталость от игры. Тем не менее, вы можете забронировать 1 час игры для компании до 6 человек, если людей в компании больше, то минимальное количество игрового времени для брони -  от 2 часов.',
        question10:
          'Каким образом будет организована игра, если мест для игры 4, а игроков будет больше?',
        answer10:
          'Не переживайте! Администратор организует игровое время так, чтобы все гости смогли играть по очереди. Атмосфера в клубе такая, что весело будет не только играть, но и наблюдать за происходящим, поэтому по окончанию праздника все останутся довольны.',
        question11: 'Можем ли мы взять свой алкоголь, еду и соки/газировку?',
        answer11:
          'Из-за особенностей игрового процесса, играть в состоянии алкогольного опьянения запрещено. Но вы можете взять с собой еду и безалкогольные напитки чтобы сделать паузу и набраться сил для ваших виртуальных приключений.',
        question12:
          'Будут ли посторонние люди в клубе во время проведения моего праздника?',
        answer12:
          'Нет. Клуб арендуется исключительно для проведения Вашего праздника и помимо вас и ваших друзей в клубе не будет никаких посторонних людей.'
      },
      description: {
        title: 'Почему так круто праздновать',
        title2: 'День рождения в MATRIX?',
        details:
          'Тебе знакомы слишком реалистичные сны, в которых летаешь над пропастью, убиваешь зомби и перевоплощаешься в героев любимых комиксов? А теперь представь, что это возможно сделать в пределах одной комнаты. Обычные дни рождения в ресторане, пабе или детском развлекательном пространстве скоро станут пережитками прошлого, зато симуляторы виртуальной реальности приобретают всё большую популярность. Представь, что ты исследуешь дно океана, стреляешь из лука и управляешь пиратским кораблем.',
        detail2:
          'В твоем распоряжении будет всё игровое пространство клуба, где ты сможешь провести время большой компанией и все вы сможете насладится игровым процессом. Вы сможете попробовать большое количество игр, играя в одной команде, друг против друга или каждый индивидуально.',
        detail3:
          'Вас будут ограничивать лишь ваши желания. В случае если вы почувствовали что проголодались и захотите перекусить и отдохнуть от игры, вы можете сделать это у нас в клубе, предварительно заказав еду, или же взяв её с собой. Также, вы сможете принести торт со сладостями и торжественно задуть свечи, загадав при этом желание. После перекуса, набравшись сил, ты снова сможешь погрузится в виртуальный мир. Таким образом, ваша компания проведет праздник, который принесет вам много положительных эмоций!'
      },
      modal: {
        date: 'Дата',
        request: 'Оформить заявку',
        name: 'Ваше имя',
        phone: 'Номер телефона*',
        choose_club: 'Выберите клуб',
        note: 'Комментарий',
        book: 'Забронировать'
      }
    },
    gift: {
      firstScreen: {
        title1: 'Нужен',
        title2: 'крутой',
        title3: 'подарок?',
        details1: 'Дари эмоции своим близким',
        details2: 'вместе с клубом виртуальной',
        details3: 'реальности MATRIX',
        cost: 'Стоимость в',
        currency: 'грн',
        hint: '*цена может отличаться в других городах'
      },
      card: {
        title1: 'ПОДАРОЧНАЯ',
        title2: 'КАРТА НА 1 ЧАС',
        description1: 'удивления, непонимания где ты',
        description2: 'находишься и желание повторить',
        orderCard: 'Заказать карту'
      },
      receive: {
        caption1: "Подарочная карта дает право на 1 час игры одному человеку в том клубе, где была приобретена эта карта.",
        caption2: "Можно приобрести неограниченное количество карт.",
        caption3: "Мы предлагаем 2 способа получить карту:",
        title: 'Как получить карту?',
        first: 'Первый способ',
        details0: 'Приобрести подарочные сертификаты непосредственно в клубе, предварительно договорившись о дне и времени вашего визита.',
        details2_0: 'Мы отправим их вам Новой Почтой по предоплате на карту.',
        details1: 'Можете забрать карты',
        details2: 'непосредственно у нас в клубе,',
        details3: 'предварительно договорившись',
        details4: 'с администратором',
        second: 'Второй способ',
        details5: 'Мы можем отправить их',
        details6: 'вам Новой почтой по',
        details7: 'предоплате на карту'
      },
      description: {
        title: 'Почему это крутой подарок?',
        details1:
          'Ищете крутой подарок для своих близких? Мы поможем вам и предлагаем',
        details2: 'купить сертификат в клуб виртуальной реальности!',
        unlimited:
          'Cрок действия сертификата 1 год, получатель сам выбирает время его использования',
        family:
          'Возможность погружения от 1 до 4 человек одновременно, поэтому это может быть крутым подарком для всей семьи',
        games: 'Большое количество классных игр на любой вкус и возраст',
        thanks:
          'После визита в MATRIX, вас еще долго будут благодарить за такой подарок, а не просто положат его на полку с ненужными вещами'
      },
      about: {
        what: 'Что такое',
        matrix: 'MATRIX?',
        watch: 'Посмотри это видео и ты поймешь, почему более 100 000',
        tried: 'человек уже опробовали на себе что такое'
      },
      contact: {
        us: 'Свяжитесь с нами для',
        purchase: 'покупки сертификата:'
      },
      modal: {
        order: 'Заказать карту',
        name: 'Ваше имя',
        phone: 'Номер телефона*',
        quantity: 'К-во карт',
        uah: 'грн',
        choose_club: 'Выберите клуб',
        note: 'Коментарий',
        send: 'Отправить заявку'
      }
    },
    thankYou: {
      title: 'Спасибо!',
      order1: 'Ваша заявка принята, мы скоро вам перезвоним.',
      order2: 'Бронь игрового сеанса считается действительной',
      order3: 'только после подтверждения оператором колл-центра.',
      hint1: 'Согласно Закону Украины “О предотвращении',
      hint2: 'распостранения коронавируса” для входа в клуб нужна',
      hint3: 'защитная маска',
      rules: 'Не забываем о правилах',
      backToMain: 'На главную'
    },
    404: {
      oops: 'Упс...',
      notFound: 'Такой страницы не существует',
      backToMain: 'Вернуться на главную'
    },
    gamelist: {
      whatToPlay: 'Во что можно поиграть?',
      gameGenres: 'Игры по жанрам:',
      allGames: 'Все игры',
      reset: 'Сбросить',
      moreDetails: 'Подробнее'
    }
  },
  en: {
    chooseCity: "Choose your city",
    navbar: {
      prices: 'Prices',
      contacts: 'Contacts',
      birthdays: 'Birthdays',
      certificates: 'Gift cards',
      book: 'Book',
      games: 'Games',
      reviews: 'Reviews',
      faq: 'FAQ',
      country: 'Ukraine'
    },
    footer: {
      country: 'Ukraine'
    },
    main: {
      aboutUs: {
        whyYou: 'Why will you',
        comeAgain: 'come and play again?',
        fullFreedom: 'Absolute freedom',
        freedomDetails:
          'The player can easily move around 10 m2. All players movements are tracked and transferred to the virtual world',
        closedClub: 'Closed club',
        closedDetails:
          'You can enter the club only with a password (you can find it out using our chat bot for Viber and Telegram)',
        atmosphere: 'Special atmosphere',
        atmosphereDetails:
          'At the entrance to club you will be met by administrator - your guide to the world of virtual reality. With their assistant, they will introduce you to something that you did not even suspect',
        multiplayerGame: 'Online game',
        multiplayerDetails:
          'Play against each other or together against everyone! (from 1 to 4 people at the same time)'
      },
      booking: {
        title: 'Book',
        time: 'the',
        gameIn: 'game in',
        rightNow: 'right now!'
      },
      description: {
        welcome: 'Welcome',
        future: 'to the future',
        vrIn: 'Virtual reality in ',
        vrInDetails: 'Virtual reality in ',
        details:
          'is still a novelty today, but it has already gained great popularity. The MATRIX club allows visitors to travel from the real world to the virtual world, where incredible adventures await them. And they directly depend on the choice of games. Participants can be transferred to an underwater ship or study chemical compounds, they can fight aliens, monsters who want to take over the Earth.',
        showAll: 'Show all',
        moreDetails:
          'Club MATRIX offers its clients only those games that are marked by excellent graphics and clear images. This is the key to deep immersion and your positive emotions. Also, with us you have not met the primitive games, which consist in the transfer of items. Each game has its own story, which is much more interesting.',
        wantToPlay: 'Book now'
      },
      firstScreen: {
        title: 'Check out new technologies',
        vnIn: 'virtual reality in',
        offer1: 'If you don’t like it —',
        offer2: 'we will give you a refund!',
        wantToPlay: 'Book now'
      },
      price: {
        offer1: 'If you don’t like it -',
        offer2: 'we will give you a refund!',
        weekdays: ({time}) => `WEEKDAYS UNTIL ${time}:00`,
        weekdaysDetails:
          'An hour of surprise, misunderstanding where you are and the desire to repeat',
        currency: 'uah',
        duration: '60 MINUTES*',
        book: 'Book',
        weekends: ({time}) => `WEEKDAYS AFTER ${time}:00 AND WEEKENDS`,
        weekendsDetails:
          'An hour of delight, dizzying chases and crowds of dead monsters',
        giftCards: 'GIFT CARDS',
        moreDetails: 'Show more',
        birthdays: 'BIRTHDAYS',
        hint1: '* The price is for 1 person.',
        hint2:
          'Session duration 60 min. It includes a briefing and 50 minutes of playing time. When visiting MATRIX VR again, there is no need to conduct a briefing, so the playing time increases.',
        allWeek: 'WEEKDAYS and weekends'
      },
      reviews: {
        title: 'Reviews',
        real: 'of real',
        people: 'people',
        rating: 'Rating',
        outOf: 'out of',
        goToReview: 'Перейти к отзыву'
      },
      rules: {
        title: 'Rules',
        our: 'of our',
        club: 'club',
        password: 'Password',
        passwordDetails:
          'To enter the club, you must know the passphrase. It can be found using our chat bot for Viber or Telegram',
        alcohol: 'Alcohol',
        warning:
          'Please refrain from visiting the club under the influence of alcohol or drugs.',
        age: 'Age',
        restriction: 'limit',
        restrictionDetails:
          'We do not recommend children under 10 years old to visit the club. This is due to the technical features of the equipment, as well as the individual perception of the child.',
        pregnant: 'Pregnant',
        pregnantDetails:
          'We strongly do not recommend to play pregnant and emotionally impressionable people',
        visit: 'Visiting time',
        visitDetails:
          'Please arrive no earlier than 5 minutes before the session, and in case of delay, notify the administrator',
        required: 'Mandatory',
        childAccompany: 'child support',
        accompanyDetails:
          'Children under the age of 12 can only be in the club if accompanied by at least one of the adult proxies'
      },
      games: {
        title: 'What can you',
        play: 'play?',
        playersShort: 'Q-ty of players:',
        playersLong: 'Quantity of players:',
        genre: 'Genre:',
        description: 'Description:',
        book: 'Book',
        fullGameList: 'Full list of games',
        shooter: 'Shooter',
        children: 'For kids',
        sport: 'Sport',
        simulator: 'Simulator',
        quest: 'Quest',
        horror: 'Horror',
        magic: 'Magic',
        stategy: 'Strategy',
        'social-network': 'Social network'
      },
      contacts: {
        cta: 'Come, call!',
        partnership: 'For cooperation',
        proposals: 'and proposals:',
        wantToPlay: 'Book now',
        city: 'city'
      }
    },
    birthday: {
      firstScreen: {
        modern: 'Modern',
        birthday: 'Birthday',
        birthdayFor: 'for adults and children in MATRIX',
        wantToPlay: 'Order a holiday'
      },
      about: {
        whyUs: 'With us you will receive',
        fullFreedom: 'Complete freedom and team play',
        freedomDetails:
          'During the game, you move in a specially equipped space of 10m2, where the movements of the participants are monitored. You and your friends are fully interactive in the game and cannot distinguish which components of the virtual events are unrealistic as the immersion in the digital world is amazing.',
        atmosphere: 'Special atmosphere',
        atmosphereDetails:
          'Our guide to the world of virtual reality is well aware of the entertainment industry, so he will select a game program in accordance with your wishes. Keep in mind that time in the virtual world flies too fast.',
        emotions: 'Unforgettable emotions',
        emotionsDetails:
          'The first immersion in virtual space cannot be compared with anything. The brain quickly adapts to a new role, the body is controlled by manipulators and controllers, so it seems that the bow and arrow are familiar attributes. Administrator will reveal several storyline secrets and your team will defeat the orcs. How about a birthday like this?',
        alternative: 'Alternative for children',
        alternativeDetails:
          "Virtual reality has many games that do not have a detrimental effect on the child's psyche, but they develop useful skills, teach teamwork and bring up leadership skills. Can a passive rest at the computer be compared with a virtual weekend in a park, cooler than Disneyland?",
        wantToBook: 'I want to celebrate here',
        whatYouGet: 'With us you will receive',
        whatYouGetDetails:
          "Unforgettably! We really believe that VR will be able to take you away from the mundane. The level of immersion you get from the game is very realistic. Let's reveal a few secrets and for a more detailed acquaintance we give as an example the optimal time scenario in MATRIX - 3 hours.",
        preparation: 'Meeting guests and preparing for the celebration',
        preparationDetails:
          'You can bring decor or holiday decorations, as well as discuss the list of games with administrator in advance.',
        minutes: 'min',
        instructions: 'Briefing and first playing time',
        instructionsDetails:
          'Administrator informs guests about the rules of staying in the playing space and gives brief instructions. Now you can explore the bottom of the ocean and shoot from a bow, because now your team will arrange a massacre with the orcs. It is this game that does not require special skills from the guest and is optimal for a beginner in the world of virtual reality.',
        breakTime: 'Rest break',
        breakTimeDetails:
          'We pause the fierce battles, because a bank robbery and theft of secret information are ahead.',
        secondPlay: 'Second game time',
        secondPlayDetails:
          "Yesterday you watched a video of the underwater world, and today you will meet a new reality - VR, which creates the illusion of traveling to Atlantis. Once immersed in this fantastic atmosphere, you will certainly want to repeat the experience and once again visit Batman's cave or play a survival game",
        photoTime: 'Time for photos and discussion of the VR experience',
        photoTimeDetails:
          "It's time to return to real life and collect things, publish photos, discuss the MATRIX loyalty program and new VR products."
      },
      price: {
        title: 'Price',
        weekdays19: 'WEEKDAYS UNTIL 19:00',
        weekdaysDetails:
          'An hour of surprise, misunderstanding where you are and the desire to repeat',
        currency: 'uah',
        duration: '60 MINUTES*',
        book: 'Book',
        weekends: 'WEEKDAYS AFTER 19:00 AND WEEKENDS',
        weekendsDetails:
          'An hour of delight, dizzying chases and crowds of dead monsters',
        upfront1: 'To confirm a booking, you must',
        upfront2: 'make prepayment 50%',
        allWeek: 'WEEKDAYS and weekends'
      },
      faq: {
        question1: 'From which age can my child play?',
        answer1:
          'We recommend visiting the club for children over the age of 10. This is due to the technical features of the equipment, as well as the individual perception of the child',
        question2: "My child is not yet 10 years old. Why can't he play?",
        answer2:
          "The equipment is originally designed for older children and the administrator will probably not be able to securely attach the helmet to your child's head. Therefore, there is a risk that the helmet will fall off and break during the game. Since guests are financially responsible for the equipment, we do our best to avoid such situations",
        question3: 'Do I need to leave an prepayment?',
        answer3:
          'Yes, after sending the application, the call center operator will contact you to discuss all the details of your holiday. You will need to make a 50% deposit to confirm your booking. Prepayment can be made in the form of a cashless payment or paid in cash directly at the club.',
        question4: 'Are there discounts / gifts for the birthday?',
        answer4:
          'We do not provide additional discounts for birthdays. But every MATRIX guest after the celebration will have the opportunity to purchase a MATRIX gift card under a special offer 2 for the price of 1. Also, guests will receive every 5th game for free.',
        question5: 'Do parents have to be in the club during the game?',
        answer5:
          'Yes, we recommend that one or two parents stay with their children for the entire playing time in the club. Games in virtual reality are quite active, so additional supervision of children for safety purposes will not be superfluous.',
        question6:
          'My child looks older than his peers. He knows how to follow the rules, and in general I am sure that there will be no problems with him. Can he come to MATRIX?',
        answer6:
          "Yes, we can book a game for you. But we warn that in two cases the administrator can still cancel it. The first is if the helmet cannot be securely fastened to the child's head. The second is if the child's behavior during the game in virtual reality provokes the risk of harm to themselves, others, or equipment. In this case, we will not take money for a visit, because your positive emotions are more valuable to us. It may be worth waiting a bit until the child grows up so that he can fully enjoy the process of the game.",
        question7:
          "The child wants to play, but I can't (or don't want to) be with him in the club. Can I leave him alone for the duration of the game?",
        answer7:
          'Children can perceive what is happening in virtual reality distorted, and during the game, harm themselves, others, or damage equipment. If your child is under 15 years of age, you may not leave them at the club without the additional supervision of at least one parent or other adult trustee.',
        question8: 'Is this service suitable for children?',
        answer8:
          "Of course, we will be happy to host a children's birthday party for guests aged 10 and over. For younger children, we strongly recommend that you consider some other entertainment, because VR is not recommended for children of this age.",
        question9:
          'What is the recommended amount of time for a birthday 2-3 hours? Can we book 1 hour?',
        answer9:
          'In our experience, two hours of play is the gold standard for 4-person parties. In the first hour, everyone will get a taste and get comfortable in virtual reality. In the second hour, more complex and interesting games and missions will be introduced. We recommend three hours for large companies of more than 4 people, as well as for hyperactive children. In other cases, it is better to limit yourself to two hours. It is much better when there is a slight feeling of understatement and a desire to return to virtual reality as soon as possible than fatigue from the game. However, you can book 1 hour of game for a company of up to 6 people, if there are more people in the company, then the minimum amount of game time for booking is from 2 hours.',
        question10:
          'How will the game be organized if there are 4 places to play, and there will be more players?',
        answer10:
          'Do not worry! Administrator arranges playing time so that all guests can play in turn. The atmosphere in the club is such that it will be fun not only to play, but also to watch what is happening, so at the end of the holiday everyone will be satisfied.',
        question11: 'Can we bring our own alcohol, food and juice/soda?',
        answer11:
          'Due to the nature of the gameplay, playing while intoxicated is prohibited. But you can take food and non-alcoholic drinks with you to pause and recharge for your virtual adventures.',
        question12: 'Will there be strangers in the club during my party?',
        answer12:
          'No. The club is rented exclusively for your holiday and there will be no strangers in the club besides you and your friends.'
      },
      description: {
        title: 'Why is it so cool to celebrate',
        title2: 'Birthday in MATRIX?',
        details:
          "Do you know too realistic dreams in which you fly over the abyss, kill zombies and reincarnate as the heroes of your favorite comics? Now imagine that it is possible to do this within the same room. Ordinary birthday parties in a restaurant, pub or children's entertainment space will soon become a thing of the past, but virtual reality simulators are becoming increasingly popular. Imagine that you are exploring the bottom of the ocean, archery and control a pirate ship.",
        detail2:
          'At your disposal will be the entire playing space of the club, where you can spend time with a large company, and you can all enjoy the gameplay. You can try a large number of games, playing in one team, against each other or individually.',
        detail3:
          'You will be limited only by your desires. If you feel hungry and want to have a snack and relax from the game, you can do it at our club, pre-ordering food or taking it with you. Also, you can bring a cake with sweets and solemnly blow out the candles, making a wish. After a snack, gaining strength, you will be able to plunge into the virtual world again. In this way, your company will hold a holiday that will bring you a lot of positive emotions!'
      },
      modal: {
        date: 'Date',
        request: 'Make a request',
        name: 'Your name',
        phone: 'Phone number*',
        choose_club: 'Choose a club',
        note: 'Comment',
        book: 'Book'
      }
    },
    gift: {
      firstScreen: {
        title1: 'Need a',
        title2: 'cool',
        title3: 'gift?',
        details1: 'Give emotions to your loved ones',
        details2: 'together with the virtual',
        details3: 'reality club MATRIX',
        cost: 'Price in',
        currency: 'uah',
        hint: '*price may vary in other cities'
      },
      card: {
        title1: 'GIFT',
        title2: 'CARD FOR 1 HOUR',
        description1: 'surprise, misunderstanding where you are',
        description2: 'and the desire to repeat',
        orderCard: 'Order card'
      },
      receive: {
        caption1: "The gift card entitles one person to 1 hour of play at the club where the card was purchased.",
        caption2: "You can purchase an unlimited number of cards.",
        caption3: "We offer 2 ways to receive the card:",
        title: 'How to get a card?',
        first: 'First way',
        details0: 'Purchase gift certificates directly at the club by arranging the date and time of your visit in advance.',
        details2_0: 'We will send them to you via Nova Poshta with prepayment to the card.',
        details1: 'You can take the cards',
        details2: 'directly in our club,',
        details3: 'having previously agreed',
        details4: 'with the administrator',
        second: 'Second way',
        details5: 'We can send them',
        details6: 'to you by Nova Poshta by',
        details7: 'prepayment on a card'
      },
      description: {
        title: 'Why is this a great gift?',
        details1:
          'Looking for a cool gift for your loved ones? We will help you and offer',
        details2: 'buy a certificate to the virtual reality club!',
        unlimited:
          'The certificate is valid for 1 year, the recipient himself chooses the time of its use',
        family:
          'Ability to play from 1 to 4 people together, so it can be a cool gift for the whole family',
        games: 'Lots of cool games for everyone',
        thanks:
          'After a visit to MATRIX, you will be thanked for such a gift for a long time, and not just put it on a shelf with unnecessary things'
      },
      about: {
        what: 'What is',
        matrix: 'MATRIX?',
        watch: 'Watch this video and you will understand why more than 100 000',
        tried: 'people have already experienced what it is'
      },
      contact: {
        us: 'Contact us to',
        purchase: 'purchase a certificate:'
      },
      modal: {
        order: 'Order a card',
        name: 'Your name',
        phone: 'Phone number*',
        quantity: 'Q-ty of cards',
        uah: 'uah',
        choose_club: 'Choose a club',
        note: 'Comment',
        send: 'Submit'
      }
    },
    thankYou: {
      title: 'Thanks!',
      order1: 'Your application has been accepted, we will call you back soon.',
      order2: 'The booking of the gaming session is considered valid',
      order3: 'only after confirmation by the call center operator.',
      hint1: 'According to the Law of Ukraine “On Prevention',
      hint2: 'he Spread of Coronavirus”, a protective mask is required',
      hint3: 'to enter the club',
      rules: "Don't forget the rules",
      backToMain: 'Back to main page'
    },
    404: {
      oops: 'Oops...',
      notFound: 'This page does not exist',
      backToMain: 'Back to main page'
    },
    gamelist: {
      whatToPlay: 'What can you play?',
      gameGenres: 'Games by genre:',
      allGames: 'All games',
      reset: 'Reset',
      moreDetails: 'More'
    }
  }
}
